import '../CSS/main.css'; // use your own css file
import { useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

export default function QrCode() {
  const [dataUrl, setDataUrl] = useState('');
  const { idEmployee } : any = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_ENDPOINT}/qrcode?id=${idEmployee}&company=${searchParams.get('company')}&format=png`,
      {
        headers: new Headers({
          'X-API-Key': `${process.env.REACT_APP_APIKEY}` 
        })
      }
    )
      .then(async (res) => {
        if (!res.ok) throw new Error('Generation failed');
        const localDataUrl = await res.json();
        if (localDataUrl.format === 'png') setDataUrl(localDataUrl.qrCode);
      })
      .catch(() => {
        document.location.href = `${process.env.REACT_APP_HREF_ALTYOR}`; 
      });
  }, []);

  return (
    <div className="App">
      <h2>
        {`QR Code for ${idEmployee}`}
      </h2>

      <div>
        <img alt="QrCode" src={dataUrl} />
      </div>
    </div>
  );
}
