import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import Main from './Components/Main';
import QrCode from './Components/QRCode';

function MainPage() {
  // 👇️ redirect to external URL
  window.location.replace(`${process.env.REACT_APP_HREF_ALTYOR}`);
  return null;
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:idEmployee" element={<Main />} />
        <Route path="/:idEmployee/qrcode" element={<QrCode />} />
        <Route path="*" element={<MainPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
