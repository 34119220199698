import ModelUserInfo from './ModelUserInfo';

export default class ModelVCard {
  begin: string;
  version: string;
  name: string;
  company:string;
  title:string;
  tel: string;
  email: string;
  adress: string;
  webSite: string;
  photo:string;
  end: string;

  /**
     * 
     * @param firstName 
     * @param lastName 
     * @param tel 
     * @param mode 1 altyor, 2 nodon, 3 tiloli
     */
  constructor(userInfo:ModelUserInfo) {
    this.begin = 'BEGIN:VCARD\r\n';
    this.version = 'VERSION:3.0\r\n';
    this.name = `N:${userInfo.lastName};${userInfo.firstName};;;\r\n`; // N:Name;FirstName;;;
    this.company = `ORG:${userInfo.company};\r\n`;
    this.title = `TITLE:${userInfo.jobTitle}\r\n`;
    this.tel = `TEL;WORK:${userInfo.phone}\r\n`;
    this.email = `EMAIL;WORK:${userInfo.email}\r\n`;
    this.adress = `ADR;WORK:${userInfo.officeLocation}\r\n`; // ADR;WORK:;;Street;City;;Postal code;Country
    this.webSite = `URL:${userInfo.webSite}\r\n`;
    this.photo = `PHOTO;TYPE=JPG;ENCODING=B:${userInfo.base64Photo}\r\n`;
    this.end = 'END:VCARD\r\n';
  }

  toString() {
    return this.begin + this.version + this.name + this.company + this.title + this.tel + this.email + this.adress + this.webSite + this.photo + this.end;
  }
}
