export default class ModelUserInfo {
  mode:number;
  displayName: string; // "Martin Dupont"
  firstName:string;
  lastName:string;
  jobTitle: string; // "Développeur Junior | Junior developer"
  email: string;
  phone: string;
  officeLocation: string;
  urlPhoto:string;
  base64Photo:string;
  resume:string;
  webSite:string;
  company:string;

  constructor() {
    this.mode = 0;
    this.displayName = '';
    this.firstName = '';
    this.lastName = '';
    this.jobTitle = '';
    this.email = '';
    this.phone = '';
    this.officeLocation = '';
    this.urlPhoto = '';
    this.base64Photo = '';
    this.resume = '';
    this.webSite = '';
    this.company = '';
  }

  populate(data: any) {
    this.mode = ModelUserInfo.getMode(data.email);
    this.displayName = data.displayName; // cant't be void
    this.firstName = data.givenName;
    this.lastName = data.surname;
    data.jobTitle ? this.jobTitle = data.jobTitle : this.jobTitle = 'Employee';
    this.email = data.email; // can't be void
    // eslint-disable-next-line no-nested-ternary
    this.phone = (data.phone.length === 0 && this.mode !== 4) ? '+33238250090' : (data.phone.length === 0 && this.mode === 4) ? '+862164429991' : data.phone;
    this.officeLocation = (this.mode === 4) ? '上海市闵行区颛兴东路 1421 弄152号' : '121 rue des Hêtres - 45590 Saint-Cyr-en-Val - France';
    this.urlPhoto = data.urlPhoto;
    this.base64Photo = data.base64Photo ? data.base64Photo : '';
    this.resume = ModelUserInfo.generateResume(this.mode);
    this.webSite = ModelUserInfo.generateWebSite(this.mode);
    this.company = ModelUserInfo.generateCompany(this.mode);
  }

  static generateResume(mode:number):string {
    const altyor = "Designs and produces innovative objects in an eco-responsible approach to achieve our clients' ambitions with dedicated teams, proven industrial expertise and a deep desire to participate in the ecological and social transition.";
    const nodon = 'French company specialized in accessories for the connected home and connected building. We design a complete range of wireless products that make smart homes easy to use.';
    const tiloli = 'Leading partner for the design and distribution of premium accessories dedicated to digital nomadism. An essential link between its suppliers and its expert clients, Tiloli is also a new business explorer.';

    switch (mode) {
      case 2:
        return nodon;
      case 3:
        return tiloli;
      default:
        return altyor;
    }
  }

  static generateWebSite(mode:number) {
    let website:string;
    switch (mode) {
      case 2:
        website = 'nodon.fr';
        break;
      case 3:
        website = 'tiloli.fr';
        break;
      default:
        website = 'altyor.com';
        break;
    }
    return website;
  }

  static generateCompany(mode:number):string {
    let company:string;
    switch (mode) {
      case 2:
        company = 'Nodon';
        break;
      case 3:
        company = 'Tiloli';
        break;
      default:
        company = 'Altyor';
        break;
    }
    return company;
  }

  static getMode(email:string):number {
    let mode:number;

    const domain:string = email.split('@')[1];
    switch (domain) {
      case 'nodon.fr':
        mode = 2;        
        break;
      case 'tiloli.fr':
        mode = 3;        
        break;
      case 'altyor.cn':
        mode = 4;        
        break;
      default:
        mode = 1;
        break;
    }
    return mode;
  }
}
