import '../CSS/main.css';

import { useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ModelVCard from '../Models/ModelVCard';
import ModelUserInfo from '../Models/ModelUserInfo';
import Spinner from './Spinner';

function createVCF(userInfo:ModelUserInfo) {
  const employeeVCard:ModelVCard = new ModelVCard(userInfo);
  const vcard = new Blob([employeeVCard.toString()], { type: 'text/vcard' });

  return URL.createObjectURL(vcard);
}

function Main() {
  const [userInfo, setUserInfo] = useState(new ModelUserInfo());
  const params:any = useParams();

  const [searchParams] = useSearchParams();
  const company:string = searchParams.get('company') !== null ? searchParams.get('company')! : 'undefined';
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_ENDPOINT}/user?name=${params.idEmployee}&company=${company}`,
      {
        headers: new Headers({
          'X-API-Key': `${process.env.REACT_APP_APIKEY}` 
        })
      }
    )
      .then((res) => {
        if (!res.ok) throw new Error('Employee not found');
        return res.json();
      })
      .then((json) => {
        const user = new ModelUserInfo();
        user.populate(json);
        setUserInfo(user);
        setIsLoading(false);
      })
      .catch(() => {
        document.location.href = `${process.env.REACT_APP_HREF_ALTYOR}`; // need .env.production
      });
  }, []);

  const mainRendering = (

    <>
      <div id="vcardHeader">

        <div id="title">
          <img alt="Avatar" id="vcardAvatar" src={userInfo.urlPhoto} />
          <h1>{userInfo.displayName}</h1>
          <div id="employeeRole">
            <span>{` ${userInfo.jobTitle}`}</span>
          </div>
        </div>
      </div>
      <div id="information">
        <nav>
          <a href={`tel:${userInfo.phone}`}>
            <img className="icon" src="/icon/AltyorVcard_Phone-Call.svg" alt="Call" />
            <span>Call</span>
          </a>
          <a href={`mailto:${userInfo.email}`}>
            <img className="icon" src="/icon/AltyorVcard_Mail-Send.svg" alt="Mail" />
            <span>Email</span>
          </a>
          <a id="addContact" href={createVCF(userInfo)} download={`${params.idEmployee}.vcf`}>
            <img className="icon" src="/icon/AltyorVcard_Contact-add.svg" alt="Contact" />
            <span>Add contact</span>
          </a>
        </nav>
        <div id="resume">
          <div className="rowData">
            <img id="logo" src={`/logo/Logo${userInfo.company}.png`} alt="Altyor" />

            <div>{userInfo.resume}</div>
          </div>
        </div>

        <div className="vcardInformationRow">
          <img className="icon" src="/icon/AltyorVcard_Phone.svg" alt="Phone" />
          <div className="rowData">
            <div>{userInfo.phone}</div>
            <div className="informationRowTitle">Phone</div>
          </div>
        </div>

        <div className="vcardInformationRow">
          <img className="icon" src="/icon/AltyorVcard_Mail.svg" alt="Mail" />
          <div className="rowData">
            <div>{userInfo.email}</div>
            <div className="informationRowTitle">Email</div>
          </div>
        </div>

        <div className="vcardInformationRow">
          <img className="icon" src="/icon/AltyorVcard_Contact.svg" alt="Status" />
          <div className="rowData">
            <div>{userInfo.company}</div>
            <div className="informationRowTitle">{userInfo.jobTitle}</div>
          </div>
        </div>

        <div className="vcardInformationRow">
          <img className="icon" src="/icon/AltyorVcard_Pin-Map.svg" alt="Localisation" />
          <div className="rowData">
            <div>{userInfo.officeLocation}</div>
          </div>
        </div>

      </div>
      <footer>
        <a id="button" href={`/${params.idEmployee}/qrcode?company=${userInfo.company.toLowerCase()}`}>Get QRCode</a>
        <a id="button" href={`https://${userInfo.webSite}`}>{`Visit ${userInfo.webSite}`}</a>
      </footer>

    </>

  );

  return (
    <div id="top">
      <header className="headerResponsive" />
      <div id="vcardBody">
        {isLoading ? <Spinner /> : mainRendering}
      </div>
    </div>
  );
}

export default Main;
